import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed",
  style: {}
}
const _hoisted_2 = {
  key: 0,
  class: "text-end me-5 mt-5"
}
const _hoisted_3 = { class: "d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20" }
const _hoisted_4 = {
  href: "#",
  class: "mb-12"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  alt: "Logo",
  src: "/media/avatars/blank.png",
  class: "h-50px logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
                this.$store.getters.currentSettings &&
                this.$store.getters.currentSettings.en &&
                this.$store.getters.currentSettings.fr &&
                this.$route.name !== 'sign-in' &&
                this.$route.name !== 'password-reset' && 
                this.$route.name !== 'change-password'
            )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.getLocaleLang() === 'en')
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLocale('fr'))),
                class: "btn btn-icon btn-light"
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("img", {
                  alt: "Logo",
                  src: "/media/france.png",
                  class: "w-50"
                }, null, -1)
              ])))
            : (_ctx.getLocaleLang() === 'fr')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLocale('en'))),
                  class: "btn btn-icon btn-light"
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("img", {
                    alt: "Logo",
                    src: "/media/royaume-uni.png",
                    class: "w-50"
                  }, null, -1)
                ])))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", _hoisted_4, [
        (this.$store.getters.currentSettings.clientLogo)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              alt: "Logo",
              src: this.$store.getters.currentSettings.clientLogo,
              class: "h-50px logo"
            }, null, 8, _hoisted_5))
          : (_openBlock(), _createElementBlock("img", _hoisted_6))
      ]),
      _createVNode(_component_router_view),
      _cache[4] || (_cache[4] = _createElementVNode("a", {
        href: "https://www.obea.fr/",
        target: "_blank",
        class: "mt-12"
      }, [
        _createElementVNode("img", {
          alt: "Logo",
          src: "/media/logo_obea.svg",
          class: "h-45px"
        })
      ], -1))
    ])
  ]))
}